import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import * as moment from "moment";
import 'moment-timezone';
import React from "react";
import { FormattedMessage, FormattedNumber, injectIntl, intlShape } from "react-intl";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import processingIcon from '../../media/img/icons/processing-icon.svg';
import * as constants from "../../util/constants";
import { parseDateForPad } from "../../util/dates";
import Alert from "./Alert";
import ButtonClose from "./ButtonClose";
import FieldDate from "./FieldDate";
import FieldSelect from "./FieldSelect";
import FieldText from "./FieldText";
import FieldTextarea from "./FieldTextarea";
import Modal from "./Modal";
import Propertii from "./Propertii";
import ReceiptPaymentTransaction from "./ReceiptPaymentTransaction";
import ReceiptScheduledPayment from "./ReceiptScheduledPayment";
import Spinner from "./Spinner";
import Table from "./Table";

class Transactions extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            selectedTab: '',

            reversedAmount: 0,
            reversedFeeAmount: 0,

            paymentTransactionSearchFilter: '',
            scheduledPaymentSearchFilter: '',
            transactionProcessorFilter: null,
            transactionTypeFilter: null,
            transactionStatusFilter: null,
            transactionInitiatedViaFilter: null,
            recurrenceTypeFilter: null,
            processingStatusFilter: null,
            padScheduleFilter: null,

            startDate: null,
            endDate: null,

            manager:{},

            padSchedule: false,

            customChargeCodes: [],
            charges: [{}],
            paymentDate: '',
            settlementDate: '',

            paymentTransaction: {},
            scheduledPayment: {},
            parentPaymentTransaction: {},

            paymentTransactionList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            scheduledPaymentList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            paymentTransactionReversalList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            paymentTransactionQuery: {
                orderBy: 'DESC',
                orderByFields: ['createDate'],
                conditionList: [],
                joins: {
                    pl: {
                        targetRecordType: 'TYPE_PROPERTY_LEASE',
                        joinField: 'billingAccountId',
                        alias: 'pl',
                        returnFields: ['propertyId', 'companyId', 'unit', 'accountNumber']
                    },
                    p: {
                        targetRecordType: 'TYPE_PROPERTY',
                        joinField: 'propertyId',
                        alias: 'p',
                        returnFields: ['propertyName', 'street1']
                    },
                    c: {
                        targetRecordType: 'TYPE_CUSTOMER',
                        joinField: 'userId',
                        alias: 'c',
                        returnFields: ['firstName', 'lastName', 'email', 'userIdentifier']
                    },
                    co: {
                        targetRecordType: 'TYPE_COMPANY',
                        joinField: 'companyId',
                        alias: 'co',
                        returnFields: ['name', 'chargeCodes', 'integrationId', 'landlordId']
                    },
                    pc: {
                        targetRecordType: 'TYPE_COMPANY',
                        joinField: 'co.parentId',
                        alias: 'pc',
                        returnFields: ['name', 'chargeCodes', 'integrationId', 'landlordId']
                    },
                    installmentPay:{
                        targetRecordType: 'TYPE_INSTALLMENT_PAY',
                        joinField: 'paymentMethodId',
                        alias: 'installmentPay',
                        returnFields: ['id', 'installmentDetails', 'type', 'installments', 'externalId']
                    },
                    m: {
                        targetRecordType: 'TYPE_MANAGER',
                        joinField: 'recipientId',
                        alias: 'm',
                        returnFields: ['id', 'firstName', 'lastName']
                    }
                }
            },

            scheduledPaymentQuery: {
                orderBy: 'DESC',
                orderByFields: ['scheduledDate'],
                conditionList: [],
                joins: {
                    pl: {
                        targetRecordType: 'TYPE_PROPERTY_LEASE',
                        joinField: 'billingAccountId',
                        alias: 'pl',
                        returnFields: ['id', 'propertyId', 'unit', 'accountNumber']
                    },
                    p: {
                        targetRecordType: 'TYPE_PROPERTY',
                        joinField: 'pl.propertyId',
                        alias: 'p',
                        returnFields: ['id', 'propertyName', 'street1', 'propertyIdentifier']
                    },
                    c: {
                        targetRecordType: 'TYPE_CUSTOMER',
                        joinField: 'userId',
                        alias: 'c',
                        returnFields: ['firstName', 'lastName', 'userIdentifier']
                    },
                    co: {
                        targetRecordType: 'TYPE_COMPANY',
                        joinField: 'pl.companyId',
                        alias: 'co',
                        returnFields: ['id', 'name', 'chargeCodes', 'integrationId', 'landlordId']
                    },
                    pc: {
                        targetRecordType: 'TYPE_COMPANY',
                        joinField: 'pl.companyId',
                        alias: 'pc',
                        returnFields: ['id', 'name', 'chargeCodes', 'integrationId', 'landlordId']
                    },
                    cc: {
                        targetRecordType: 'TYPE_CREDIT_CARD',
                        joinField: 'paymentMethodId',
                        alias: 'cc',
                        returnFields: ['brand', 'last4']
                    },
                    ba: {
                        targetRecordType: 'TYPE_BANK_ACCOUNT',
                        joinField: 'paymentMethodId',
                        alias: 'ba',
                        returnFields: ['last4']
                    }
                }
            },

            validationList: [],
            generatedFiles: 0 
        };

        this.filterPaysafeCashCondition = [
            {
                "type": "STRING",
                "logicalOperator": "AND",
                "openBrackets": "((",
                "closeBrackets": null,
                "operator": "EQUALS",
                "fieldName": "paymentProviderId",
                "fieldValue": "PAYSAFE_CASH"
            },
            {
                "type": "STRING",
                "logicalOperator": "AND",
                "openBrackets": null,
                "closeBrackets": null,
                "operator": "NOT_EQUALS",
                "fieldName": "status",
                "fieldValue": "PENDING"
            }, 
            {
                "type": "STRING",
                "logicalOperator": "AND",
                "openBrackets": null,
                "closeBrackets": ")",
                "operator": "NOT_EQUALS",
                "fieldName": "status",
                "fieldValue": "TIMEDOUT"
            }, 
            {
                "type": "STRING",
                "logicalOperator": "OR",
                "openBrackets": null,
                "closeBrackets": null,
                "operator": "EQUALS",
                "fieldName": "paymentProviderId",
                "fieldValue": null
            },
            {
                "type": "STRING",
                "logicalOperator": "OR",
                "openBrackets": null,
                "closeBrackets": ")",
                "operator": "NOT_EQUALS",
                "fieldName": "paymentProviderId",
                "fieldValue": "PAYSAFE_CASH"
            }
        ];

        this.selectTab = this.selectTab.bind(this);
        this.searchManager = this.searchManager.bind(this);

        this.searchPaymentTransactions = this.searchPaymentTransactions.bind(this);
        this.searchScheduledPayments = this.searchScheduledPayments.bind(this);
        this.searchPaymentTransactionReversals = this.searchPaymentTransactionReversals.bind(this);

        this.filterPaymentTransactions = this.filterPaymentTransactions.bind(this);
        this.filterScheduledPayments = this.filterScheduledPayments.bind(this);
        this.clearFilters = this.clearFilters.bind(this);

        this.viewPaymentTransaction = this.viewPaymentTransaction.bind(this);
        this.viewScheduledPayment = this.viewScheduledPayment.bind(this);

        this.savePaymentTransactionSettlementDate = this.savePaymentTransactionSettlementDate.bind(this);
        this.savePaymentTransactionStatus = this.savePaymentTransactionStatus.bind(this);

        this.stopScheduledPayment = this.stopScheduledPayment.bind(this);

        this.refundPaymentTransaction = this.refundPaymentTransaction.bind(this);
        this.pushPaymentTransaction = this.pushPaymentTransaction.bind(this);
        this.manuallyPushPaymentTransaction = this.manuallyPushPaymentTransaction.bind(this);
        this.cancelPaymentTransaction = this.cancelPaymentTransaction.bind(this);
        this.saveNote = this.saveNote.bind(this);
    }

    /**
     * Populate the list of transactions on mounting of the component.
     */
    componentDidMount() {
        this.transactionsMountFunc();
    }

    /**
     * Watch for updates to props
     * @param {*} prevProp 
     */
    componentDidUpdate(prevProp) {
        if(this.props.userId !== prevProp.userId) {
            this.transactionsMountFunc();
        }
    }

    /**
     * Init function for this component
     */
    transactionsMountFunc() {

        if(this.props.userType === 'TYPE_MANAGER'){
            this.searchManager();
        }

        if(this.props.history && this.props.history.location.state && this.props.history.location.state.paymentTransaction) {
            this.selectTab('PAYMENT_TRANSACTIONS');
            this.viewPaymentTransaction(this.props.history.location.state.paymentTransaction);
        }

        if(this.props.history && this.props.history.location.state && this.props.history.location.state.scheduledPayment) {
            this.selectTab('SCHEDULED_PAYMENTS');
            this.viewScheduledPayment(this.props.history.location.state.scheduledPayment);
        }

        if(this.props.userType === 'TYPE_ADMIN' && this.props.global) {
            //If Admin we want to add start date, set to last two months, as default filter. 
            const date = moment().subtract(2, 'months').startOf('day').format('YYYY-MM-DD[T]HH:mm:ssZZ');
            const paymentTransactionQuery = this.state.paymentTransactionQuery;
            paymentTransactionQuery.conditionList.push(
                {
                    type: 'DATE',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'createDate',
                    operator: 'GREATER_THAN_OR_EQUALS',
                    fieldValue: date
                },
            );

            this.setState(prevState => ({
                ...prevState,
                startDate: date,
                paymentTransactionQuery: paymentTransactionQuery
            }), this.selectTab('PAYMENT_TRANSACTIONS'));
        } else {
            this.selectTab('PAYMENT_TRANSACTIONS');
        }
    }

    /**
     * Select a tab from the list of navigation tabs.
     *
     * @param tabId - The ID of the tab selected.
     */
    selectTab(tabId) {

        let scheduledPaymentsModal = $('#scheduled-payments');
        let paymentTransactionsModal = $('#payment-transactions');

        switch(tabId) {

            case "PAYMENT_TRANSACTIONS":

                this.searchPaymentTransactions(1, 25, this.state.paymentTransactionQuery);
                scheduledPaymentsModal.tab('dispose');
                paymentTransactionsModal.tab('show');

                break;

            case "SCHEDULED_PAYMENTS":

                this.searchScheduledPayments(1, 25, this.state.scheduledPaymentQuery);
                paymentTransactionsModal.tab('dispose');
                scheduledPaymentsModal.tab('show');

                break;

            default:

                this.searchPaymentTransactions(1, 25, this.state.paymentTransactionQuery);
                scheduledPaymentsModal.tab('dispose');
                paymentTransactionsModal.tab('show');

                break;

        }
    }

    /**
     * Handle submitting the search filter field for payment transactions.
     *
     * @param event - The event container.
     */
    filterPaymentTransactions(event) {

        if (event != null) {
            event.preventDefault();
        }

        this.setState(prevState => ({
            ...prevState,
            spinner: true,
            validationList: [],
        }));

        let paymentTransactionQuery = this.state.paymentTransactionQuery;

        paymentTransactionQuery.conditionList = [];

        if(this.state.paymentTransactionSearchFilter !== '') {
            paymentTransactionQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: '(',
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'id',
                    fieldValue: this.state.paymentTransactionSearchFilter
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'confirmationId',
                    fieldValue: this.state.paymentTransactionSearchFilter
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'compressedConfirmationId',
                    fieldValue: this.state.paymentTransactionSearchFilter
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: ')',
                    operator: 'LIKE_IGNORE_CASE',
                    fieldName: 'p.propertyName,p.street1,pl.unit,c.firstName,c.lastName,c.email,c.userIdentifier,customUnit',
                    fieldValue: this.state.paymentTransactionSearchFilter
                }
            );
        }

        if(this.state.transactionProcessorFilter !== null && this.state.transactionProcessorFilter !== '') {

            if(this.state.transactionProcessorFilter === 'APPLE_PAY' ||
                this.state.transactionProcessorFilter === 'GOOGLE_PAY'){

                paymentTransactionQuery.conditionList.push(
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        fieldName: 'walletType',
                        operator: 'EQUALS',
                        fieldValue: this.state.transactionProcessorFilter
                    },
                );

            } else {

                paymentTransactionQuery.conditionList.push(
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        fieldName: 'paymentProviderId',
                        operator: 'EQUALS',
                        fieldValue: this.state.transactionProcessorFilter
                    },
                );
            }
        }

        if(this.state.transactionTypeFilter !== null && this.state.transactionTypeFilter !== '') {
            paymentTransactionQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'transactionType',
                    operator: 'EQUALS',
                    fieldValue: this.state.transactionTypeFilter
                },
            );
        }

        if(this.state.transactionStatusFilter !== null && this.state.transactionStatusFilter !== '') {
            paymentTransactionQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'status',
                    operator: 'EQUALS',
                    fieldValue: this.state.transactionStatusFilter
                },
            );
        }

        if(this.state.transactionInitiatedViaFilter !== null && 
                this.state.transactionInitiatedViaFilter !== '' && 
                this.state.transactionInitiatedViaFilter !== 'SPLITIT' &&
                this.state.transactionInitiatedViaFilter !== 'SPLITIT_FEE') {
            paymentTransactionQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'source',
                    operator: 'EQUALS',
                    fieldValue: this.state.transactionInitiatedViaFilter
                },
            );

        } else if(this.state.transactionInitiatedViaFilter === 'SPLITIT'){
            paymentTransactionQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'paymentMethodType',
                    operator: 'EQUALS',
                    fieldValue: 'TYPE_INSTALLMENT_PAY'
                },
            );
            
        } else if(this.state.transactionInitiatedViaFilter === 'SPLITIT_FEE'){
            paymentTransactionQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'transactionType',
                    operator: 'EQUALS',
                    fieldValue: 'FEE_PAYMENT'
                },
            );
        }

        if(this.state.startDate !== null) {
            const startDateTemp = moment(this.state.startDate).startOf('day').format('YYYY-MM-DD[T]HH:mm:ssZZ');
            paymentTransactionQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: '((',
                    closeBrackets: null,
                    fieldName: 'source',
                    operator: 'EQUALS',
                    fieldValue: 'PAD'
                },
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'transactionType',
                    operator: 'EQUALS',
                    fieldValue: 'PAYMENT'
                },
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'processedDate',
                    operator: 'NOT_EQUALS',
                    fieldValue: null
                },
                {
                    type: 'DATE',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: ')',
                    fieldName: 'processedDate',
                    operator: 'GREATER_THAN_OR_EQUALS',
                    fieldValue: startDateTemp
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: '((',
                    closeBrackets: null,
                    fieldName: 'source',
                    operator: 'NOT_EQUALS',
                    fieldValue: 'PAD'
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'transactionType',
                    operator: 'NOT_EQUALS',
                    fieldValue: 'PAYMENT'
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: ')',
                    fieldName: 'processedDate',
                    operator: 'EQUALS',
                    fieldValue: null
                },
                {
                    type: 'DATE',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: '))',
                    fieldName: 'createDate',
                    operator: 'GREATER_THAN_OR_EQUALS',
                    fieldValue: startDateTemp
                },
            );
        }

        if(this.state.endDate !== null) {
            const endDateTemp = moment(this.state.endDate).endOf('day').format('YYYY-MM-DD[T]HH:mm:ssZZ');
            paymentTransactionQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: '((',
                    closeBrackets: null,
                    fieldName: 'source',
                    operator: 'EQUALS',
                    fieldValue: 'PAD'
                },
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'transactionType',
                    operator: 'EQUALS',
                    fieldValue: 'PAYMENT'
                },
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'processedDate',
                    operator: 'NOT_EQUALS',
                    fieldValue: null
                },
                {
                    type: 'DATE',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: ')',
                    fieldName: 'processedDate',
                    operator: 'LESS_THAN_OR_EQUALS',
                    fieldValue: endDateTemp
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: '((',
                    closeBrackets: null,
                    fieldName: 'source',
                    operator: 'NOT_EQUALS',
                    fieldValue: 'PAD'
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'transactionType',
                    operator: 'NOT_EQUALS',
                    fieldValue: 'PAYMENT'
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: ')',
                    fieldName: 'processedDate',
                    operator: 'EQUALS',
                    fieldValue: null
                },
                {
                    type: 'DATE',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: '))',
                    fieldName: 'createDate',
                    operator: 'LESS_THAN_OR_EQUALS',
                    fieldValue: endDateTemp
                },
            );
        }

        this.setState({
            paymentTransactionQuery: paymentTransactionQuery
        });

        this.searchPaymentTransactions(1, 25, paymentTransactionQuery);
    }

    /**
     * Clear all applicable filters and re-run the filter queries.
     */
    clearFilters() {

        this.setState({
            paymentTransactionSearchFilter: '',
            scheduledPaymentSearchFilter: '',
            transactionProcessorFilter: null,
            transactionTypeFilter: null,
            transactionStatusFilter: null,
            transactionInitiatedViaFilter: null,
            recurrenceTypeFilter: null,
            processingStatusFilter: null,
            padScheduleFilter: null,
            startDate: null,
            endDate: null,
        }, () => {

            if(this.props.displayAdvanced) {
                this.filterScheduledPayments();
            }

            this.filterPaymentTransactions();

        });
    }

    /**
     * Handle submitting the search filter field for scheduled payments.
     *
     * @param event - The event container.
     */
    filterScheduledPayments(event) {

        if (event != null) {
            event.preventDefault();
        }

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        let scheduledPaymentQuery = this.state.scheduledPaymentQuery;

        scheduledPaymentQuery.conditionList = [];

        if(this.state.scheduledPaymentSearchFilter === '') {

            if(this.props.propertyId) {
                scheduledPaymentQuery.conditionList.push(
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        operator: 'EQUALS',
                        fieldName: 'pl.propertyId',
                        fieldValue: this.props.propertyId
                    }
                );
            }

            if(this.props.companyId) {
                scheduledPaymentQuery.conditionList.push(
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        operator: 'EQUALS',
                        fieldName: 'pl.companyId',
                        fieldValue: this.props.companyId
                    }
                );
            }

            if(this.props.userId) {
                scheduledPaymentQuery.conditionList.push(
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        operator: 'EQUALS',
                        fieldName: 'userId',
                        fieldValue: this.props.userId
                    }
                );
            }

            if(this.props.billingAccountId) {
                scheduledPaymentQuery.conditionList.push(
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        operator: 'EQUALS',
                        fieldName: 'billingAccountId',
                        fieldValue: this.props.billingAccountId
                    }
                );
            }
        }

        if(this.state.scheduledPaymentSearchFilter !== '') {
            scheduledPaymentQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: '(',
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'id',
                    fieldValue: this.state.scheduledPaymentSearchFilter
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: ')',
                    operator: 'LIKE_IGNORE_CASE',
                    fieldName: 'pl.unit,p.street1,c.firstName,c.lastName,c.userIdentifier',
                    fieldValue: this.state.scheduledPaymentSearchFilter
                }
            );
        }

        if(this.state.processingStatusFilter !== null && this.state.processingStatusFilter !== '') {
            scheduledPaymentQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'status',
                    operator: 'EQUALS',
                    fieldValue: this.state.processingStatusFilter
                },
            );
        }

        if(this.state.padScheduleFilter !== null && this.state.padScheduleFilter !== '') {
            scheduledPaymentQuery.conditionList.push(
                {
                    type: 'BOOLEAN',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'padSchedule',
                    operator: 'EQUALS',
                    fieldValue: this.state.padScheduleFilter === 'true'
                },
            );
        }

        if(this.state.startDate !== null) {
            scheduledPaymentQuery.conditionList.push(
                {
                    type: 'DATE',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'createDate',
                    operator: 'GREATER_THAN_OR_EQUALS',
                    fieldValue: this.state.startDate
                },
            );
        }

        if(this.state.endDate !== null) {
            scheduledPaymentQuery.conditionList.push(
                {
                    type: 'DATE',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'createDate',
                    operator: 'LESS_THAN_OR_EQUALS',
                    fieldValue: this.state.endDate
                },
            );
        }

        this.setState({
            scheduledPaymentQuery: scheduledPaymentQuery
        });

        this.searchScheduledPayments(1, 25, scheduledPaymentQuery);
    }
    /**
     * Load the record for the manager to determine if they are an admin
     */
    searchManager(){
        axios.get(`${constants.REACT_APP_HOST_API_URL}/manager/${this.props.parent.props.userSession.sessionRole.id}`,{
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                manager: response.data
            }));
            
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * 
     * Download transactions report.
     * Files with 20,000 records
     * 2 months between start date and end date
     */
    downloadTransactionsReport() {
        this.setState(prevState => ({
            ...prevState,
            validationList: [{
                fields: {},
                alert: {}
            }],
        }));
        if (!this.state.startDate || !this.state.endDate) {
            this.setState(prevState => ({
                ...prevState,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'danger',
                        message: 'Start date and end date with no more than two months are required to download the report'
                    }
                }],
            }));
            return
        }

        if ( moment(this.state.startDate).startOf('day').format('YYYY-MM-DD[T]HH:mm:ssZZ') !== this.state.paymentTransactionQuery.conditionList.find(element => element.operator === "GREATER_THAN_OR_EQUALS")?.fieldValue
        || moment(this.state.endDate).endOf('day').format('YYYY-MM-DD[T]HH:mm:ssZZ') !== this.state.paymentTransactionQuery.conditionList.find(element => element.operator === "LESS_THAN_OR_EQUALS")?.fieldValue) {
            this.setState(prevState => ({
                ...prevState,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'danger',
                        message: 'Must update the search to download the report'
                    }
                }],
            }));
            return
        }

        const pages = Math.ceil(this.state.paymentTransactionList.totalRecordCount / 20000)
        this.setState(prevState => ({
                ...prevState,
                generatedFiles: pages
        }))
        for (let i = 1; i <= pages; i++) {
            const totalTransactions = i === pages ? (this.state.paymentTransactionList.totalRecordCount - ((pages-1) * 20000)) : 20000
            this.searchPaymentTransactions(i, totalTransactions, this.state.paymentTransactionQuery, true, i)
        }
    }

    getCSVContentBody(records){
        return records.map(e => {
            let str = "";
            if (e?.source === "PAD"){
                str = str.concat(parseDateForPad(e.createDate));
                str = str.concat(",")
            } else {
                str = str.concat(`${e.createDate},`)
            }

            str = str.concat(e.currency === "CAD" ? `CA$${(e.amount).toFixed(2)},` : `$${(e.amount).toFixed(2)},`)
            if(this.props.userType === 'TYPE_ADMIN') {
                str = str.concat(e.currency === "CAD" ? `CA$${(e.feeAmount).toFixed(2)},` : `$${(e.feeAmount).toFixed(2)},`)
                str = str.concat(`"${e?.confirmationId || ""}",`)
            }
            str = str.concat(`"${e.joins?.co?.name}",`)
            str = str.concat(`"${e.joins?.p?.propertyName || ""}",`)
            str = str.concat(`"${e.joins?.p?.street1 || ""}",`)
            str = str.concat(`${(e.joins?.pl?.unit ? e.joins.pl.unit : e.customUnit) || ""},`)
            str = str.concat(`${e.joins?.c?.firstName} ${e.joins?.c?.lastName},`)
            str = str.concat(`${e.status},`)
            if(this.props.userType === 'TYPE_ADMIN') {
                str = str.concat(`${e.paymentProviderId},`)
                str = str.concat(`${e.last4}`)
            }
            return str
        });
    }

    /**
     * Search for a list of payment transactions.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchPaymentTransactions(page, recordsPerPage, query, isReport = false, fileNumber = 0) {

        this.setState(prevState => ({
            ...prevState,
            spinner: true,
            selectedTab: 'PAYMENT_TRANSACTIONS',
        }));

        let conditionList = query.conditionList;

        // Search transactions by property ID, if provided
        if(this.props.propertyId) {
            conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'propertyId',
                    fieldValue: this.props.propertyId
                }
            );
        }

        // Search transactions by company ID, if provided
        if(this.props.companyId) {
            conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'companyId',
                    fieldValue: this.props.companyId
                }
            );
        }
        // Search transactions by user ID, if provided
        if(this.props.userId) {
            conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'userId',
                    fieldValue: this.props.userId
                }
            );
        } else if(this.props.billingAccountId) {
            //Search transactions by billing account ID, if provided
            conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'billingAccountId',
                    fieldValue: this.props.billingAccountId
                }
            );
        }

        if(this.props.userType !== 'TYPE_ADMIN') {
            conditionList.push(
                {
                    type: 'NUMBER',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'NOT_EQUALS',
                    fieldName: 'amount',
                    fieldValue: 0
                }
            );

            conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'NOT_EQUALS',
                    fieldName: 'transactionType',
                    fieldValue: 'FEE_PAYMENT'
                }
            );
        }

        // Hide pending and timed out paysasfe cash transactions for everyone except CS Admins.
        if(this.props.userType !== 'TYPE_ADMIN') {
            conditionList.push(...this.filterPaysafeCashCondition);
        }

        const mainTableFields = [
            "amount",
            "billingAccountId",
            "billingAccountType",
            "cardBrand",
            "cardType",
            "charges",
            "companyId",
            "confirmationId",
            "compressedConfirmationId",
            "createDate",
            "currency",
            "customUnit",
            "disbursementStatus",
            "error",
            "feeAmount",
            "feePaidByCompany",
            "feeProfileId",
            "fees",
            "fundsTransferId",
            "hostedPaymentPageId",
            "id",
            "integrationBatchId",
            "last4",
            "leaseApplicationId",
            "merchantAccountId",
            "parentId",
            "paymentMethodId",
            "paymentMethodType",
            "paymentProviderId",
            "processedDate",
            "propertyId",
            "payoutStatus",
            "returnReason",
            "reversedAmount",
            "reversedFeeAmount",
            "settlementDate",
            "source",
            "status",
            "transactionType",
            "type",
            "updateDate",
            "userId",
            "userType",
            "feeType",
            "note"
        ];

        const reportTableFields = [
            "amount",            
            "createDate",
            "currency",
            "customUnit",
            "feeAmount",
            "status",
            "type",
            "source",
            "paymentProviderId",
            "last4",
            "confirmationId"
        ]

        axios.post(`${constants.REACT_APP_HOST_API_URL}/payment_transaction/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            mainTableFields: isReport ? reportTableFields : mainTableFields,
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList,
            joins: query.joins
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            if (!isReport) {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    scheduledPaymentList: {
                        page: '',
                        recordsPerPage: '',
                        totalPages: '',
                        totalRecordCount: '',
                        records: [
                            {}
                        ]
                    },
                    paymentTransactionList: {
                        ...response.data,
                        emptyUnfilteredList: this.state.paymentTransactionSearchFilter === '' && response.data.records.length === 0
                    }
                }));
            } else {
                const FileDownload = require('js-file-download');
                let csvContentHeader = ["Date, Transaction Amount"]
                if(this.props.userType === 'TYPE_ADMIN') {
                    csvContentHeader = [csvContentHeader[0].concat(`, Fee Amount`)];
                    csvContentHeader = [csvContentHeader[0].concat(`, Confirmation Id`)];
                }
                csvContentHeader = [csvContentHeader[0].concat(`, Company, Property Name, Property Address, Unit, Tenant, Status`)];
                if(this.props.userType === 'TYPE_ADMIN') {
                    csvContentHeader = [csvContentHeader[0].concat(`, Payment Provider`)];
                    csvContentHeader = [csvContentHeader[0].concat(`, Last4`)];
                }
                const csvContentBody = this.getCSVContentBody(response.data.records)
                const csvContent = csvContentHeader.concat(csvContentBody).join("\n")
                FileDownload(csvContent, `transaction_report_${fileNumber}.csv`);
                if (this.state.generatedFiles > 1) {
                    this.setState(prevState => ({
                        ...prevState,
                        generatedFiles: this.state.generatedFiles - 1,
                    }))
                } else {
                    this.setState(prevState => ({
                        ...prevState,
                        spinner: false,
                    }))
                }
            }
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Search for a list of scheduled payments.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchScheduledPayments(page, recordsPerPage, query) {

        this.setState(prevState => ({
            ...prevState,
            spinner: true,
            selectedTab: 'SCHEDULED_PAYMENTS',
        }));

        let conditionList = query.conditionList;

        // Search transactions by property ID, if provided
        if(this.props.propertyId) {
            conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'pl.propertyId',
                    fieldValue: this.props.propertyId
                },
            );
        }

        // Search transactions by company ID, if provided
        if(this.props.companyId) {
            conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'pl.companyId',
                    fieldValue: this.props.companyId
                }
            );
        }

        // Search transactions by user ID, if provided
        if(this.props.userId) {
            conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'userId',
                    fieldValue: this.props.userId
                }
            );
        }

        // Search transactions by billing account ID, if provided
        if(this.props.billingAccountId) {
            conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'billingAccountId',
                    fieldValue: this.props.billingAccountId
                },
            );
        }

        axios.post(`${constants.REACT_APP_HOST_API_URL}/scheduled_payment/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList,
            joins: query.joins
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let scheduledPayments = response.data;

            scheduledPayments.records.forEach((scheduledPayment, key) => {

                let amount = 0;

                scheduledPayment.charges.forEach((data, key) => {
                    amount += data.amount;
                });

                scheduledPayments.records[key].amount = amount;
            });

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                paymentTransactionList: {
                    page: '',
                    recordsPerPage: '',
                    totalPages: '',
                    totalRecordCount: '',
                    records: [
                        {}
                    ]
                },
                scheduledPaymentList: {
                    ...response.data,
                    emptyUnfilteredList: this.state.scheduledPaymentSearchFilter === '' && response.data.records.length === 0
                },
                scheduledPaymentQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList,
                    joins: query.joins
                }
            }));


        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Search for a list of reversal payment transactions related to the transaction currently being viewed.
     */
    searchPaymentTransactionReversals() {

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        let paymentTransactionReversalQuery = {
            orderBy: 'DESC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'parentId',
                    fieldValue: this.state.paymentTransaction.id
                }
            ],
            joins: this.state.paymentTransactionQuery.joins
        };

        axios.post(`${constants.REACT_APP_HOST_API_URL}/payment_transaction/search`, {
            orderBy: paymentTransactionReversalQuery.orderBy,
            orderByFields: paymentTransactionReversalQuery.orderByFields,
            conditionList: paymentTransactionReversalQuery.conditionList,
            joins: paymentTransactionReversalQuery.joins
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                paymentTransactionReversalList: response.data,
            }));

            $('#receipt-payment-transaction').modal('hide');
            $('#payment-transaction-reversals').modal('show');

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * View the detailed information of a payment transaction. If the transaction has a parent ID, search for the parent
     * transaction in order to display both transactions in a side-by-side view in the receipt modal. If the joined
     * company of the payment transaction has an integration ID, get the transaction data from the integration service.
     *
     * @param paymentTransaction - The payment transaction to view the details of.
     */
    viewPaymentTransaction(paymentTransaction) {

        //reset some of the state in the case a new payment is viewed
        this.setState(prevState => ({
            ...prevState,
            validationList: [],
            reversedAmount: 0,
            reversedFeeAmount: 0,
        }));

        if(paymentTransaction.parentId) {
            axios.post(`${constants.REACT_APP_HOST_API_URL}/payment_transaction/search?recordsPerPage=1&page=1`, {
                orderBy: this.state.paymentTransactionQuery.orderBy,
                orderByFields: this.state.paymentTransactionQuery.orderByFields,
                conditionList: [
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        operator: 'EQUALS',
                        fieldName: 'id',
                        fieldValue: paymentTransaction.parentId
                    }
                ],
                joins: this.state.paymentTransactionQuery.joins
            }, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    parentPaymentTransaction: response.data.records[0],
                    validationList: [],
                }));

            }).catch(error => {
                this.handleValidation(error);
            });
        }

        if( paymentTransaction.joins
            && ((paymentTransaction.joins.co && paymentTransaction.joins.co.integrationId) || (paymentTransaction.joins.pc && paymentTransaction.joins.pc.integrationId))
            && paymentTransaction.status === 'COMPLETE' && (paymentTransaction.transactionType === 'PAYMENT' || paymentTransaction.transactionType === 'NSF')) {
            axios.get(`${constants.REACT_APP_HOST_INTEGRATION_URL}/transaction/${paymentTransaction.id}`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                paymentTransaction.integration = response.data;

                this.setState(prevState => ({
                    ...prevState,
                    paymentTransaction: {
                        ...paymentTransaction,
                        integration: response.data
                    }
                }));

            }).catch(error => {
                if(error.response?.data?.errorCode === "RM_ERROR_RECORD_NOT_FOUND" && error.response?.data?.message?.includes("INTEGRATION_TRANSACTION"))
                    error.response.data.message = "Associated INTEGRATION_TRANSACTION id not found."
                this.handleValidation(error);
            });
        }

        if(paymentTransaction.feeProfileId && this.props.userType !== 'TYPE_MANAGER') {
            axios.get(`${constants.REACT_APP_HOST_API_URL}/fee_profile/${paymentTransaction.feeProfileId}`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                paymentTransaction.feeProfile = response.data;

                this.setState(prevState => ({
                    ...prevState,
                    paymentTransaction: {
                        ...paymentTransaction,
                        feeProfile: response.data
                    }
                }));

            }).catch(error => {
                if(error.response?.data?.message?.includes("TYPE_FEE_PROFILE")) {
                    // Associated TYPE_FEE_PROFILE id not found, this error is not critical for the transaction receipt to show
                    // It has been requested to hide this error on FE
                    if(error.response?.data?.errorCode !== "RM_ERROR_RECORD_NOT_FOUND") {
                        this.handleValidation(error);
                    }
                } else {
                    this.handleValidation(error);
                }
            });
        }

        this.setState(prevState => ({
            ...prevState,
            paymentTransaction: {
                ...paymentTransaction
            }
        }));

        $('#payment-transaction-reversals').modal('hide');
        $('#receipt-payment-transaction').modal('show');
    }

    /**
     * View the detailed information of a scheduled payment. Initializes the scheduled payment modal.
     *
     * @param scheduledPayment - The scheduled payment to view.
     */
    viewScheduledPayment(scheduledPayment) {

        this.setState(prevState => ({
            ...prevState,
            paymentTransaction: {},
            recurringSchedule: {},
            scheduledPayment: scheduledPayment,
            validationList: [],
        }));

        $('#receipt-scheduled-payment').modal('show');
    }

    /**
     * Save an existing payment transaction's settlement date. Used by admins to manually set a settlement date for a
     * payment transaction if one does not exist.
     *
     * @param event - The event container.
     */
    savePaymentTransactionSettlementDate(event) {

        event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        let paymentTransaction = this.state.paymentTransaction;

        axios.patch(`${constants.REACT_APP_HOST_API_URL}/payment_transaction/${paymentTransaction.id}/update`, {
            settlementDate: this.state.settlementDate,
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'common.transactions.payment.settled'
                    }
                }],
            }));

            $('#settle-payment-transaction').modal('hide');

            this.searchPaymentTransactions(this.state.paymentTransactionList.page, this.state.paymentTransactionList.recordsPerPage, this.state.paymentTransactionQuery);

        }).catch(error => {

            this.handleValidation(error);

        });

        window.scrollTo(0, 0);
    }

    /**
     * Save an existing payment transaction's status. Closes all modals and updates the payment transaction list with
     * the newly updated status. If the payment transaction is being marked as complete, a confirmation ID must be
     * provided. If the payment transaction is being marked as failed, an error must be provided.
     *
     * @param event - The event container.
     * @param status - The new status of the payment transaction.
     */
    savePaymentTransactionStatus(event, status) {

        event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        let paymentTransaction = this.state.paymentTransaction;

        axios.patch(`${constants.REACT_APP_HOST_API_URL}/payment_transaction/${paymentTransaction.id}/update`, {
            status: status,
            confirmationId: this.state.paymentTransaction.confirmationId,
            processedDate: this.state.paymentTransaction.processedDate,
            error: null
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: status === 'COMPLETE' ? 'common.transactions.payment.completed' : 'common.transactions.payment.failed'
                    },
                    values: {
                        firstName: paymentTransaction.joins.c.firstName,
                        lastName: paymentTransaction.joins.c.lastName
                    }
                }],
            }));

            $('#complete-payment-transaction').modal('hide');
            $('#fail-payment-transaction').modal('hide');
            $('#receipt-payment-transaction').modal('hide');

            this.searchPaymentTransactions(this.state.paymentTransactionList.page, this.state.paymentTransactionList.recordsPerPage, this.state.paymentTransactionQuery);

        }).catch(error => {

            this.handleValidation(error);

        });

        window.scrollTo(0, 0);
    }

    /**
     * @param - status - new status of the transaction
     */
    updateStatus(event, status) {

        event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        let paymentTransaction = this.state.paymentTransaction;

        const params = {
            status: status,
            error: null
        }

        if(this.state.paymentTransaction?.paymentProviderId === 'PAYSAFE_CASH' && this.state.paymentTransaction?.status === 'COMPLETE' && status === 'ONHOLD') {
            params.disbursementStatus = 'NOT_DISBURSED';
            params.settlementDate = null;
        }

        axios.patch(`${constants.REACT_APP_HOST_API_URL}/payment_transaction/${paymentTransaction.id}/update`, params, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            $('#complete-payment-transaction').modal('hide');
            $('#fail-payment-transaction').modal('hide');
            $('#receipt-payment-transaction').modal('hide');

            this.searchPaymentTransactions(this.state.paymentTransactionList.page, this.state.paymentTransactionList.recordsPerPage, this.state.paymentTransactionQuery);

        }).catch(error => {

            this.handleValidation(error);

        });

        window.scrollTo(0, 0);
    }

    /**
     * Stop a scheduled payment by deleting the scheduled payment record.
     *
     * @param scheduledPaymentId - The ID of the scheduled payment.
     */
    stopScheduledPayment(scheduledPaymentId) {

        axios.delete(`${constants.REACT_APP_HOST_API_URL}/scheduled_payment/${scheduledPaymentId}/delete`,  {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'common.transactions.scheduled.stopped'
                    },
                    values: {
                        firstName: this.state.scheduledPayment.joins.c.firstName,
                        lastName: this.state.scheduledPayment.joins.c.lastName
                    }
                }],
            });

            this.searchScheduledPayments(this.state.scheduledPaymentList.page, this.state.scheduledPaymentList.recordsPerPage, this.state.scheduledPaymentQuery);

            $('#stop-scheduled-payment').modal('hide');

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Refund a transaction.
     * 
     * @param event - The event container.
     */
    refundPaymentTransaction(event) {
        // It is assumed that whatever code calls this function will verify the user type
        // that is making this call.

        event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        let refundFeeAmount = this.state.reversedFeeAmount;
        let refundAmount = this.state.reversedAmount;

        axios.post(`${constants.REACT_APP_HOST_API_URL}/processrefund`, {
            originalTransactionId: this.state.paymentTransaction.id,
            refundAmount: refundAmount,
            refundFeeAmount: refundFeeAmount,
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            if(response.data.status === 'FAILED') {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'danger',
                            code: 'common.transactions.refund.failed'
                        },
                        values: {
                            error: response.data.error
                        }
                    }],
                }));
            }

            if(response.data.status !== 'FAILED') {

                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'common.transactions.payment.refunded'
                        }
                    }],
                }));

                $('#refund-payment-transaction').modal('hide');

                this.searchPaymentTransactions(this.state.paymentTransactionList.page, this.state.paymentTransactionList.recordsPerPage, this.state.paymentTransactionQuery);

            }

        }).catch(error => {
            if(error?.response?.data?.message && this.props.userType === 'TYPE_MANAGER') {
                const errorMsg = error.response.data.message;
                if(errorMsg.includes("Amount reversal limit reached")) {
                    error.response.data.message = `Your desired refund of ${refundAmount} is greater then the refundable amount of ${(this.state.paymentTransaction.amount - this.state.paymentTransaction.reversedAmount).toFixed(2)}`;
                }
            }
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Push a transaction to the integration service.
     */
    pushPaymentTransaction() {

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.get(`${constants.REACT_APP_HOST_API_URL}/payment_transaction/${this.state.paymentTransaction.id}/push`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
            }));

            $('#push-payment-transaction').modal('hide');

            this.viewPaymentTransaction(this.state.paymentTransaction);

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * In some scenarios, a regular transaction push fails because there is no way that the client's ERP system can
     * accept the data according to their business rules. If this is the case, the transaction can be marked as manually
     * pushed.
     */
    manuallyPushPaymentTransaction() {

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.patch(`${constants.REACT_APP_HOST_INTEGRATION_URL}/transaction/${this.state.paymentTransaction.id}/update`, {
            pushStatus: 'MANUAL_ENTRY',
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'common.transactions.payment.manual'
                    }
                }],
            }));

            $('#manually-push-payment-transaction').modal('hide');

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Cancel an unsettled transaction.
     */
    cancelPaymentTransaction() {

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.post(`${constants.REACT_APP_HOST_API_URL}/cancelpayment`, {
            transactionId: this.state.paymentTransaction.id
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
            }));

            $('#cancel-payment-transaction').modal('hide');

            this.searchPaymentTransactions(this.state.paymentTransactionList.page, this.state.paymentTransactionList.recordsPerPage, this.state.paymentTransactionQuery);

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Update payment payout status
     */
    updatePaymentPayoutStatus(event, payoutStatus) {
        event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        const paymentTransaction = this.state.paymentTransaction;

        axios.patch(`${constants.REACT_APP_HOST_API_URL}/payment_transaction/${paymentTransaction.id}/update`, {
            payoutStatus: payoutStatus
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                spinner: false
            }));    

            $('#complete-payment-transaction').modal('hide');
            $('#fail-payment-transaction').modal('hide');
            $('#receipt-payment-transaction').modal('hide');
            $('#mark-HWpayment-rejected').modal('hide');
            $('#mark-HWpayment-bycheque').modal('hide');

            this.searchPaymentTransactions(this.state.paymentTransactionList.page, this.state.paymentTransactionList.recordsPerPage, this.state.paymentTransactionQuery);

        }).catch(error => {

            this.handleValidation(error);

        });

        window.scrollTo(0, 0);
    }

    /**
     * Save transaction note.
     */
    saveNote(event) {

        event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        const paymentTransaction = this.state.paymentTransaction;

        axios.patch(`${constants.REACT_APP_HOST_API_URL}/payment_transaction/${paymentTransaction.id}/update`, {
            note: this.state.paymentTransaction.note
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                spinner: false
            }));

            $('#save-note').modal('hide');

            this.searchPaymentTransactions(this.state.paymentTransactionList.page, this.state.paymentTransactionList.recordsPerPage, this.state.paymentTransactionQuery);

        }).catch(error => {

            this.handleValidation(error);

        });

        window.scrollTo(0, 0);
    }

    /**
     * Render the component.
     *
     * @returns {*} - The landlord dashboard component.
     */
    render() {

        $(function() {
            $('[data-toggle="tooltip"]').tooltip();
        });

        const {formatMessage} = this.props.intl;

        let paymentTransactionColumnLabels = {};
        let scheduledPaymentColumnLabels = {};

        let isMainPMTransactionPage = (this.props.userType === 'TYPE_MANAGER') &&
                                      !this.props.propertyId && !this.props.companyId && !this.props.userId && this.props.global;
        
        let isMainAdminTransactionPage = (this.props.userType === 'TYPE_ADMIN') &&
                                         !this.props.propertyId && !this.props.companyId && !this.props.userId && this.props.global;
        
        let defaultTextSearchPlaceholder = "Filter by name, phone, email, unit, property, compressed ID, confirmation ID, or user identifier...";

        let columnWidths = [];
        if(this.props.propertyId) {
            paymentTransactionColumnLabels = {createDate: 'Date', amount: 'Amount', 'pl.unit': 'Unit', 'c.firstName': 'Tenant', status: 'Status'};
            scheduledPaymentColumnLabels = {scheduledDate: 'Scheduled Date', charges: 'Amount', 'pl.unit': 'Unit', 'c.firstName': 'Tenant', status: 'Status'};
            columnWidths = ['20%', '20%', '20%', '20%', '20%'];
        }

        if(this.props.companyId) {
            paymentTransactionColumnLabels = {createDate: 'Date', amount: 'Amount', 'pl.unit': 'Unit', 'p.propertyName': 'Property Name', 'p.street1': 'Property Address', 'c.firstName': 'Tenant', status: 'Status'};
            scheduledPaymentColumnLabels = {scheduledDate: 'Scheduled Date', charges: 'Amount', 'pl.unit': 'Unit', 'p.propertyName': 'Property Name', 'p.street1': 'Property Address', 'c.firstName': 'Tenant', status: 'Status'};
            columnWidths = ['16%', '16%', '16%', '16%', '16%', '16%', '16%'];
        }

        if(this.props.userId || this.props.billingAccountId) {
            paymentTransactionColumnLabels = {createDate: 'Date', amount: 'Amount', 'pl.unit': 'Unit', 'p.propertyName': 'Property Name', 'p.street1': 'Property Address', status: 'Status'};
            scheduledPaymentColumnLabels = {scheduledDate: 'Scheduled Date', charges: 'Amount', 'pl.unit': 'Unit', 'p.propertyName': 'Property Name', 'p.street1': 'Property Address', status: 'Status'};
            columnWidths = ['20%', '20%', '20%', '20%', '20%', '20%'];
        }

        if(this.props.global && !isMainPMTransactionPage) {
            paymentTransactionColumnLabels = {createDate: 'Date', amount: 'Amount', 'co.name': 'Company', 'p.propertyName': 'Property Name', 'p.street1': 'Property Address', 'pl.unit': 'Unit', 'c.firstName': 'Tenant', status: 'Status'};
            scheduledPaymentColumnLabels = {scheduledDate: 'Scheduled Date', charges: 'Amount', 'co.name': 'Company', 'p.propertyName': 'Property Name', 'p.street1': 'Property Address', 'pl.unit': 'Unit', 'c.firstName': 'Tenant', status: 'Status'};
            columnWidths = ['16%', '16%', '16%', '16%', '16%', '16%', '16%', '16%'];
        }

        if(this.props.global && isMainPMTransactionPage) {
            paymentTransactionColumnLabels = {createDate: 'Date', amount: 'Amount', 'co.name': 'Company', 'p.propertyName': 'Property Name', 'p.street1': 'Property Address', 'pl.unit': 'Unit', accountNumber:'Account ID', 'c.firstName': 'Tenant', status: 'Status'};
            scheduledPaymentColumnLabels = {scheduledDate: 'Scheduled Date', charges: 'Amount', 'co.name': 'Company', 'p.propertyName': 'Property Name', 'p.street1': 'Property Address', 'pl.unit': 'Unit', accountNumber:'Account ID', 'c.firstName': 'Tenant', status: 'Status'};
            columnWidths = ['16%', '16%', '16%', '16%', '16%', '16%', '16%', '16%', '16%'];
        }

        return (
            <React.Fragment>

                <Spinner visible={this.state.spinner} />

                <Alert validationList={this.state.validationList} />

                <div className="card accordion" id="navigation">

                    <div className={`card-header ${this.props.displayAdvanced ? 'pb-0' : ''}`}>
                        <div className="row align-items-center">

                            <div className="col d-flex justify-content-between align-items-center">

                                {!this.props.displayAdvanced &&
                                <span>Transactions</span>
                                }

                                {this.props.displayAdvanced &&
                                    <div>
                                        <ul className="nav nav-tabs border-bottom-0">
                                            <li className="nav-item">
                                                <a className={`nav-link ${this.state.selectedTab === 'PAYMENT_TRANSACTIONS' ? 'active' : ''}`} id="payment-transactions-tab" data-toggle="tab" href="#payment-transactions" role="tab" aria-controls="payment-transactions" aria-selected="true" onClick={() => this.selectTab('PAYMENT_TRANSACTIONS')}>
                                                    Transactions
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className={`nav-link ${this.state.selectedTab === 'SCHEDULED_PAYMENTS' ? 'active' : ''}`} id="scheduled-payments-tab" data-toggle="tab" href="#scheduled-payments" role="tab" aria-controls="scheduled-payments" aria-selected="true" onClick={() => this.selectTab('SCHEDULED_PAYMENTS')}>
                                                    Scheduled Payments
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                }
                                
                                <div>
                                    {this.props.makePayment &&
                                    <Link to={this.props.makePaymentPath} className={`btn btn-primary btn-sm mb-0 ${this.state.manager.makeTenantPayments ? '' : 'disabled'}`}>
                                        <FontAwesomeIcon icon={['fas', 'usd-square']} className="fa-fw" /> Make a Payment
                                    </Link>
                                    }

                                    {this.props.userType === "TYPE_ADMIN" &&
                                    <div className="btn btn-primary btn-sm ml-3" onClick={() => this.downloadTransactionsReport()}>
                                        <FontAwesomeIcon icon={['fas', 'download']} className="fa-fw" /> Download Report
                                    </div>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className={this.props.displayAdvanced ? 'tab-content' : ''} id="tabs">

                        <div className={`${this.props.displayAdvanced ? 'tab-pane fade-show' : ''} ${this.state.selectedTab === 'PAYMENT_TRANSACTIONS' ? 'active' : ''}`} id="payment-transactions" role={this.props.displayAdvanced ? 'tabpanel' : ''} aria-labelledby="payment-transactions-tab">

                            <div className="card-header gotham border-top py-3 bg-secondary">
                                <form onSubmit={this.filterPaymentTransactions} autoComplete="off">
                                    <div className="media">
                                        <div className={`media-body align-self-center mr-3 ${this.props.widthAlign ? 'SearchFilterWidthAlign' : ''}`}>
                                            <FieldText id="paymentTransactionSearchFilter" label="Search" labelClass="d-none"
                                                       fieldColumns="12"
                                                       labelColums="0" placeholder={this.props.textSearchPlaceholderOverride ? this.props.textSearchPlaceholderOverride : defaultTextSearchPlaceholder}
                                                       parent={this}
                                                       value={this.state.paymentTransactionSearchFilter} />
                                        </div>
                                        <div className="media-body align-self-center mr-3">
                                                <FieldDate id="startDate" labelColumns="0"
                                                           fieldColumns="12" parent={this} value={this.state.startDate}
                                                           selectsRange={true} startDate={this.state.startDate}
                                                           endDate={this.state.endDate}/>
                                        </div>
                                        {this.props.widthAlign && 
                                            <style>{`
                                            .SearchFilterWidthAlign{
                                                flex: 1 1 ${this.props.widthAlign};
                                                max-width: ${this.props.widthAlign};
                                            }

                                            `}</style>}
                                        {(this.props.global && this.props.userType === 'TYPE_ADMIN') &&
                                        <React.Fragment>
                                            <div className="media-body align-self-center mr-3">
                                                <FieldSelect id="transactionProcessorFilter" labelColumns="0"
                                                             fieldColumns="12" parent={this}
                                                             value={this.state.transactionProcessorFilter}>
                                                    <option value="">All Processors</option>
                                                    <option value="PAYSAFE_CASH">Paysafe Cash</option>
                                                    <option value="PAYSAFE_SPLIT_PAY">Paysafe Split-Pay</option>
                                                    <option value="PAYPAL">PayPal</option>
                                                    <option value="GEOSWIFT">Geoswift</option>
                                                    <option value="LOADHUB">Loadhub</option>
                                                    <option value="RENTMOOLA_PP">Letus</option>
                                                    <option value="BMO">BMO</option>
                                                    <option value="BLUESNAP">BlueSnap</option>
                                                    <option value="APPLE_PAY">Apple Pay</option>
                                                    <option value="GOOGLE_PAY">Google Pay</option>
                                                </FieldSelect>
                                            </div>
                                           
                                        </React.Fragment>
                                        }
                                        {!this.props.global &&
                                        <div className="align-self-center text-right">
                                            <div className="btn-group" role="group" aria-label="Basic example">
                                                <button type="submit" className="btn btn-secondary btn-sm mb-0">
                                                    <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw"/> Search
                                                </button>
                                                <div className="btn btn-secondary btn-sm mb-0" onClick={() => this.clearFilters()}>
                                                    <FontAwesomeIcon icon={['fas', 'eraser']} className="fa-fw"/> Clear
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                    
                                        {this.props.global &&
                                        <div className="media pt-2">
                                            <React.Fragment>
                                                <div className="media-body align-self-center mr-3">
                                                    <FieldSelect id="transactionTypeFilter" labelColumns="0"
                                                                fieldColumns="12" parent={this}
                                                                value={this.state.transactionTypeFilter}>
                                                        <option value="">All Types</option>
                                                        <option value="PAYMENT">Payment</option>
                                                        <option value="NSF">NSF</option>
                                                        <option value="CHARGEBACK">Chargeback</option>
                                                        <option value="CHARGEBACK_REVERSAL">Reverse Chargeback</option>
                                                        <option value="REFUND">Refund</option>
                                                        <option value="RETURN">Return</option>
                                                    </FieldSelect>
                                                </div>
                                                <div className="media-body align-self-center mr-3">
                                                    <FieldSelect id="transactionStatusFilter" labelColumns="0"
                                                                fieldColumns="12" parent={this}
                                                                value={this.state.transactionStatusFilter}>
                                                        <option value="">All Statuses</option>
                                                        <option value="PENDING">Pending</option>
                                                        <option value="INPROCESS">Processing</option>
                                                        {/* <option value="ONHOLD">On Hold</option> */}
                                                        <option value="CANCELLED">Cancelled</option>
                                                        <option value="COMPLETE">Complete</option>
                                                        <option value="FAILED">Failed</option>
                                                        <option value="TIMEDOUT">Timed Out</option>
                                                    </FieldSelect>
                                                </div>
                                                {this.props.userType === 'TYPE_ADMIN' &&
                                                    <div className="media-body align-self-center mr-3">
                                                        <FieldSelect id="transactionInitiatedViaFilter" labelColumns="0"
                                                                    fieldColumns="12" parent={this}
                                                                    value={this.state.transactionInitiatedViaFilter}>
                                                            <option value="">All Sources</option>
                                                            <option value="ONETIME">One Time</option>
                                                            <option value="RECURRING">Recurring</option>
                                                            <option value="PAD">PAD</option>
                                                            <option value="HOSTED">Hosted</option>
                                                            <option value="WEBHOOK">Webhook</option>
                                                            <option value="PAYBYTEXT">Txt2Pay</option>
                                                            <option value="SPLITIT">Letus Split Payment</option>
                                                            <option value="SPLITIT_FEE">Letus Split Fee</option>
                                                            <option value="MARKETPLACE">Marketplace</option>
                                                            <option value="FUNDING">Funding</option>
                                                            <option value="NSF_FEE">NSF Fee</option>
                                                        </FieldSelect>
                                                    </div>
                                                }
                                                <div className="align-self-center text-right">
                                                    <div className="btn-group" role="group" aria-label="Basic example">
                                                        <button type="submit" className="btn btn-secondary btn-sm mb-0">
                                                            <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw"/> Search
                                                        </button>
                                                        <div className="btn btn-secondary btn-sm mb-0" onClick={() => this.clearFilters()}>
                                                            <FontAwesomeIcon icon={['fas', 'eraser']} className="fa-fw"/> Clear
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        </div>
                                        }
                                </form>
                            </div>

                            {this.state.paymentTransactionList.emptyUnfilteredList &&
                            <div className="card-body bg-secondary py-5 border-top">
                                <div className="row justify-content-center">
                                    <div className="col-10">
                                        <div className="text-center text-muted">
                                            <div className="fa-stack fa-2x mb-2">
                                                <FontAwesomeIcon icon={['fas', 'square']} className="fa-2x" />
                                                <FontAwesomeIcon icon={['far', 'credit-card']} className="fa-stack-1x fa-inverse" />
                                            </div>
                                        </div>
                                        <div className="text-center text-muted">
                                            <small>
                                                Payments made by your tenants will appear here.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }

                            {!this.state.paymentTransactionList.emptyUnfilteredList &&
                            <Table
                                   columns={paymentTransactionColumnLabels}
                                   columnWidths={columnWidths}
                                   headerClass="c-pointer"
                                   data={this.state.paymentTransactionList}
                                   query={this.state.paymentTransactionQuery}
                                   sortEnabled={true}
                                   recordsEnabled={true}
                                   paginationEnabled={true}
                                   parent={this}
                                   updateFunction={this.searchPaymentTransactions}
                                   tableClass="table-bordered table-hover table-responsive w-100 d-block d-md-table table-layout-fixed"
                                   >
                                <tbody>
                                {this.state.paymentTransactionList.records.map((data, key) => {
                                    return (
                                        <tr key={key} onClick={() => this.viewPaymentTransaction(data)} className="c-pointer">
                                            <td>
                                                <div className="">
                                                    <Moment format="MMM DD, YYYY">
                                                        { data?.source === "PAD" && data?.transactionType === 'PAYMENT'
                                                        ? parseDateForPad(data.createDate)
                                                        : data.createDate}
                                                    </Moment>
                                                </div>
                                            </td>

                                            <td>
                                                {this.props.displayFeeAmount &&
                                                <React.Fragment>
                                                    {(data.amount != null && data.feeAmount == null) &&
                                                    <div className={data.amount < 0 ? 'text-danger' : ''}>
                                                        <FormattedNumber value={data.amount} style={`currency`} currency={data.currency}/>
                                                    </div>
                                                    }
                                                    {(data.amount != null && data.feeAmount != null) &&
                                                    <div className={(data.amount + data.feeAmount) < 0 ? 'text-danger' : ''}>
                                                        <FormattedNumber value={data.amount + (data.feePaidByCompany ? 0 : data.feeAmount)} style={`currency`} currency={data.currency}/>
                                                    </div>
                                                    }
                                                </React.Fragment>
                                                }
                                                {!this.props.displayFeeAmount &&
                                                <div className={data.amount < 0 ? 'text-danger' : ''}>
                                                    <FormattedNumber value={data.amount} style={`currency`} currency={data.currency}/>
                                                </div>
                                                }
                                            </td>
                                            {this.props.global &&
                                            <td>
                                                {data.joins && data.joins.co &&
                                                <div className="">
                                                    {data.joins.co.name}
                                                </div>
                                                }
                                            </td>
                                            }
                                            {!this.props.global &&
                                            <td>
                                                <div className="">
                                                    {data?.joins?.pl?.unit ? data.joins.pl.unit : data?.customUnit}
                                                </div>
                                            </td>
                                            }
                                            {this.props.propertyId &&
                                            <td>
                                                {data.joins && data.joins.c &&
                                                <div className="">
                                                    {data.joins.c.firstName} {data.joins.c.lastName}
                                                </div>
                                                }
                                            </td>
                                            }
                                            {!this.props.propertyId &&
                                            <td>
                                                {data.joins && data.joins.p &&
                                                <div className="">
                                                    {data.joins.p.propertyName}
                                                </div>
                                                }
                                            </td>
                                            }
                                            {!this.props.propertyId &&
                                            <td>
                                                {data.joins && data.joins.p &&
                                                <div className="">
                                                    {data.joins.p.street1}
                                                </div>
                                                }
                                            </td>
                                            }
                                            {this.props.companyId &&
                                            <td>
                                                {data.joins && data.joins.c &&
                                                <div className="">
                                                    {data.joins.c.firstName} {data.joins.c.lastName}
                                                </div>
                                                }
                                            </td>
                                            }
                                            {(isMainPMTransactionPage || isMainAdminTransactionPage) &&
                                            <td>
                                                <div className="">
                                                    {data?.joins?.pl?.unit ? data.joins?.pl?.unit : data?.customUnit}
                                                </div>
                                            </td>
                                            }
                                            {isMainPMTransactionPage &&
                                            <td>
                                                {data.joins && data.joins.pl &&
                                                <div className="">
                                                    {data.joins.pl.accountNumber}
                                                </div>
                                                }
                                            </td>
                                            }
                                            {this.props.global &&
                                            <td>
                                                {data.joins && data.joins.c &&
                                                <div className="">
                                                    {data.joins.c.firstName} {data.joins.c.lastName}
                                                </div>
                                                }
                                            </td>
                                            }
                                            <td>
                                                {data.status &&
                                                <div className="text-wrap">
                                                    <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.transactionStatus." + data.status + ".class"})}`}/>
                                                    <span className="ml-1"><FormattedMessage id={"enum.transactionStatus." + data.status}/></span>
                                                </div>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                            }

                        </div>

                        {this.props.displayAdvanced &&
                        <div className={`tab-pane fade-show ${this.state.selectedTab === 'SCHEDULED_PAYMENTS' ? 'active' : ''}`} id="scheduled-payments" role="tabpanel" aria-labelledby="scheduled-payments-tab">

                            <div className="card-header gotham border-top py-3 bg-secondary">
                                <form onSubmit={this.filterScheduledPayments} autoComplete="off">
                                    <div className="media">
                                        <div className={`media-body align-self-center mr-3`}>
                                            <FieldText id="scheduledPaymentSearchFilter" label="Search"
                                                       labelClass="d-none"
                                                       fieldColumns="12"
                                                       labelColums="0"
                                                       placeholder={this.props.textSearchPlaceholderOverridescheduledPayment ? this.props.textSearchPlaceholderOverridescheduledPayment : defaultTextSearchPlaceholder} 
                                                       parent={this}
                                                       value={this.state.scheduledPaymentSearchFilter}/>
                                        </div>
                                        <div className="media-body align-self-center mr-3">
                                                <FieldDate id="startDate" labelColumns="0"
                                                           fieldColumns="12" parent={this} value={this.state.startDate}
                                                           selectsRange={true} startDate={this.state.startDate}
                                                           endDate={this.state.endDate}/>
                                        </div>
                                        {this.props.global &&
                                        <React.Fragment>
                                            <div className="media-body align-self-center mr-3">
                                                <FieldSelect id="processingStatusFilter" labelColumns="0"
                                                             fieldColumns="12" parent={this}
                                                             value={this.state.processingStatusFilter}>
                                                    <option value="">All Statuses</option>
                                                    <option value="PENDING">Pending</option>
                                                    <option value="INPROCESS">Processing</option>
                                                    <option value="PROCESSED">Processed</option>
                                                    <option value="FAILED">Failed</option>
                                                </FieldSelect>
                                            </div>
                                            <div className="media-body align-self-center mr-3">
                                                <FieldSelect id="padScheduleFilter" labelColumns="0"
                                                             fieldColumns="12" parent={this}
                                                             value={this.state.padScheduleFilter}>
                                                    <option value="">All Processing</option>
                                                    <option value="false">Regular Processing</option>
                                                    <option value="true">Pre-Authorized Debit Processing</option>
                                                </FieldSelect>
                                            </div>
                                        </React.Fragment>
                                        }
                                        <div className="align-self-center text-right">
                                            <div className="btn-group" role="group" aria-label="Basic example">
                                                <button type="submit" className="btn btn-secondary btn-sm mb-0">
                                                    <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw"/> Search
                                                </button>
                                                <div className="btn btn-secondary btn-sm mb-0" onClick={() => this.clearFilters()}>
                                                    <FontAwesomeIcon icon={['fas', 'eraser']} className="fa-fw"/> Clear
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            {this.state.scheduledPaymentList.emptyUnfilteredList &&
                            <div className="card-body bg-secondary py-5 border-top">
                                <div className="row justify-content-center">
                                    <div className="col-10">
                                        <div className="text-center text-muted">
                                            <div className="fa-stack fa-2x mb-2">
                                                <FontAwesomeIcon icon={['fas', 'square']} className="fa-2x"/>
                                                <FontAwesomeIcon icon={['far', 'clock']} className="fa-stack-1x fa-inverse"/>
                                            </div>
                                        </div>
                                        <div className="text-center text-muted">
                                            <small>
                                                If your tenants have one time payments scheduled for a future date, the details will be listed here.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }

                            {!this.state.scheduledPaymentList.emptyUnfilteredList &&
                            <Table columns={scheduledPaymentColumnLabels}
                                   columnWidths={columnWidths}
                                   headerClass="c-pointer"
                                   data={this.state.scheduledPaymentList}
                                   query={this.state.scheduledPaymentQuery}
                                   sortEnabled={true}
                                   recordsEnabled={true}
                                   paginationEnabled={true}
                                   parent={this}
                                   updateFunction={this.searchScheduledPayments}
                                   removeSortForColumn={'charges'}
                                   tableClass="table-bordered table-hover table-responsive w-100 d-block d-md-table table-layout-fixed"
                                   >
                                <tbody>
                                {this.state.scheduledPaymentList.records.map((data, key) => {
                                    return (
                                        <tr key={key} onClick={() => this.viewScheduledPayment(data)} className="c-pointer">
                                            <td>
                                                <div className="">
                                                    <Moment format="MMM DD, YYYY" tz="UTC">
                                                        { data?.padSchedule
                                                        ? parseDateForPad(data.scheduledDate)
                                                        : data.scheduledDate}
                                                    </Moment>
                                                </div>
                                            </td>
                                            <td>
                                                {data.amount &&
                                                <div className="">
                                                    <FormattedNumber value={data.amount} style={`currency`} currency="USD"/>
                                                </div>
                                                }
                                            </td>
                                            {this.props.global &&
                                            <td>
                                                {data.joins && data.joins.co &&
                                                <div className="">
                                                    {data.joins.co.name}
                                                </div>
                                                }
                                            </td>
                                            }
                                            {!this.props.global &&
                                            <td>
                                                <div className="">
                                                    {data?.joins?.pl?.unit ? data.joins.pl.unit : data?.customUnit}
                                                </div>
                                            </td>
                                            }
                                            {this.props.propertyId &&
                                            <td>
                                                {data.joins && data.joins.c &&
                                                <div className="">
                                                    {data.joins.c.firstName} {data.joins.c.lastName}
                                                </div>
                                                }
                                            </td>
                                            }
                                            {!this.props.propertyId &&
                                            <td>
                                                {data.joins && data.joins.p && 
                                                <div className="">
                                                    {data.joins.p.propertyName}
                                                </div>
                                                }
                                            </td>
                                            }
                                            {!this.props.propertyId &&
                                            <td>
                                                {data.joins && data.joins.p &&
                                                <div className="">
                                                    {data.joins.p.street1}
                                                </div>
                                                }
                                            </td>
                                            }
                                            {this.props.companyId &&
                                            <td>
                                                {data.joins && data.joins.c &&
                                                <div className="">
                                                    {data.joins.c.firstName} {data.joins.c.lastName}
                                                </div>
                                                }
                                            </td>
                                            }
                                            {this.props.global && !this.props.companyId && !this.props.propertyId &&
                                            <td>
                                                <div className="">
                                                    {data?.joins?.pl?.unit ? data.joins.pl.unit : data.customUnit}
                                                </div>
                                            </td>
                                            }
                                            {this.props.global &&
                                            <td>
                                                {data.joins && data.joins.c &&
                                                <div className="">
                                                    {data.joins.c.firstName} {data.joins.c.lastName}
                                                </div>
                                                }
                                            </td>
                                            }
                                            <td>
                                                {data.status &&
                                                <div className="text-wrap">
                                                    <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.transactionStatus." + data.status + ".class"})}`}/>
                                                    <span className="ml-1"><FormattedMessage id={"enum.transactionStatus." + data.status}/></span>
                                                </div>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                            }

                        </div>
                        }

                    </div>

                </div>

                <ReceiptPaymentTransaction history={this.props.history} userType={this.props.userType} displayFeeAmount={this.props.displayFeeAmount} displayAdvanced={this.props.displayAdvanced} paymentTransaction={this.state.paymentTransaction} parentPaymentTransaction={this.state.parentPaymentTransaction}>

                    <div className="float-left">
                        <ButtonClose />
                    </div>

                    <div className="float-right">

                        <div className="dropdown">

                            <div className="btn btn-primary btn-lg dropdown-toggle" role="button" id="payment-transaction-actions" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Actions
                            </div>

                            <div className="dropdown-menu" aria-labelledby="payment-transaction-actions">

                                {
                                    (this.props.userType === "TYPE_MANAGER"
                                    ? ((this.state.paymentTransaction.paymentMethodType === "TYPE_CREDIT_CARD" ||
                                            this.state.paymentTransaction.paymentMethodType === "TYPE_PAY_PAL" ||
                                            this.state.paymentTransaction.paymentMethodType === "TYPE_WALLET"))
                                    : true) &&
                                    this.state.paymentTransaction.paymentProviderId !== "PAYSAFE_CASH" &&
                                    (this.props.displayAdvanced ||
                                    this.state.manager?.roles?.includes("PRIMARY_MANAGER") ||
                                    this.state.manager?.roles?.includes("SUPPORT_MANAGER") ||
                                    this.state.manager?.roles?.includes("FINANCE_MANAGER")) &&
                                    this.state.paymentTransaction.status === "COMPLETE" &&
                                    this.state.paymentTransaction.transactionType === "PAYMENT" && (
                                    <div
                                        className="dropdown-item c-pointer"
                                        onClick={() => {
                                        $("#receipt-payment-transaction").modal("hide");
                                        $("#refund-payment-transaction").modal("show");
                                        }}
                                    >
                                        <FontAwesomeIcon icon={["fas", "undo"]} className="fa-fw" /> Refund
                                        Transaction
                                    </div>
                                    )
                                }

                                {this.props.displayAdvanced && this.props.userType === 'TYPE_ADMIN' && this.state.paymentTransaction.paymentProviderId === 'PAYSAFE_SPLIT_PAY' &&
                                ((this.state.paymentTransaction.status === 'COMPLETE' && !this.state.paymentTransaction.settlementDate) || this.state.paymentTransaction.status === 'PENDING') &&
                                (this.state.paymentTransaction.transactionType === 'PAYMENT' || this.state.paymentTransaction.transactionType === 'REFUND') &&
                                    <div className="dropdown-item c-pointer" onClick={() => {$('#receipt-payment-transaction').modal('hide'); $('#cancel-payment-transaction').modal('show');}}>
                                        <FontAwesomeIcon icon={['fas', 'undo']} className="fa-fw" /> Cancel Transaction
                                    </div>
                                }

                                {(this.props.userType !== 'TYPE_CUSTOMER' && this.props.userType !== 'TYPE_LANDLORD' && this.state.paymentTransaction.status === 'COMPLETE' && this.state.paymentTransaction.joins && ((this.state.paymentTransaction.joins.co && this.state.paymentTransaction.joins.co.integrationId) || (this.state.paymentTransaction.joins.pc && this.state.paymentTransaction.joins.pc.integrationId))) &&
                                <div className="dropdown-item c-pointer" onClick={() => {$('#receipt-payment-transaction').modal('hide'); $('#push-payment-transaction').modal('show');}}>
                                    <FontAwesomeIcon icon={['fas', 'forward']} className="fa-fw" /> Push Transaction
                                </div>
                                }

                                {(this.props.userType !== 'TYPE_CUSTOMER' && this.props.userType !== 'TYPE_LANDLORD' && this.state.paymentTransaction.status === 'COMPLETE' && this.state.paymentTransaction.joins && ((this.state.paymentTransaction.joins.co && this.state.paymentTransaction.joins.co.integrationId) || (this.state.paymentTransaction.joins.pc && this.state.paymentTransaction.joins.pc.integrationId)) && this.state.paymentTransaction.integration != null && (this.state.paymentTransaction.integration.pushStatus  === 'FAILED' || this.state.paymentTransaction.integration.pushStatus === 'TIMEDOUT' || this.state.paymentTransaction.integration.pushStatus === 'PENDING')) &&
                                <div className="dropdown-item c-pointer" onClick={() => {$('#receipt-payment-transaction').modal('hide'); $('#manually-push-payment-transaction').modal('show');}}>
                                    <FontAwesomeIcon icon={['fas', 'keyboard']} className="fa-fw" /> Mark as Manually Entered
                                </div>
                                }

                                {(this.props.displayAdvanced && (this.state.paymentTransaction.reversedAmount > 0 || this.state.paymentTransaction.reversedFeeAmount > 0)) &&
                                <div className="dropdown-item c-pointer" onClick={() => this.searchPaymentTransactionReversals()}>
                                    <FontAwesomeIcon icon={['fas', 'history']} className="fa-fw" /> Reversal History
                                </div>
                                }

                                {(this.props.displayAdvanced && !this.state.paymentTransaction.settlementDate) &&
                                <div className="dropdown-item c-pointer" onClick={() => {$('#receipt-payment-transaction').modal('hide'); $('#settle-payment-transaction').modal('show');}}>
                                    <FontAwesomeIcon icon={['fas', 'calendar-check']} className="fa-fw" /> Mark as Settled
                                </div>
                                }

                                {(this.props.displayAdvanced && this.state.paymentTransaction?.source === 'MARKETPLACE' && this.state.paymentTransaction?.payoutStatus !== 'REJECTED') &&
                                <div className="dropdown-item c-pointer" onClick={() => {$('#receipt-payment-transaction').modal('hide'); $('#mark-HWpayment-rejected').modal('show');}}>
                                    <FontAwesomeIcon icon={['fa', 'ban']} className="fa-fw" /> Payment rejected by Manager
                                </div>
                                }

                                {(this.props.displayAdvanced && this.state.paymentTransaction?.source === 'MARKETPLACE' && this.state.paymentTransaction?.payoutStatus !== 'CHEQUE') &&
                                <div className="dropdown-item c-pointer" onClick={() => {$('#receipt-payment-transaction').modal('hide'); $('#mark-HWpayment-bycheque').modal('show');}}>
                                    <FontAwesomeIcon icon={['fa', 'money-check']} className="fa-fw" /> Payment sent by cheque
                                </div>
                                }

                                {(this.props.displayAdvanced && this.state.paymentTransaction.status === 'ONHOLD') &&
                                <div className="dropdown-item c-pointer" onClick={(e) => this.updateStatus(e, 'INPROCESS')}>
                                    <img src={processingIcon} alt="processing icon" style={{width: '1.25em'}} className={`fa-fw va-b mr-1 ${this.props.upperNav ? 'mr-md-1' : 'mr-md-0'}`}/> Mark as Processing
                                </div>
                                }

                                {/* {(this.props.displayAdvanced && (this.state.paymentTransaction?.status === 'INPROCESS' || this.state.paymentTransaction?.status === 'COMPLETE') && this.state.paymentTransaction.paymentProviderId === 'PAYSAFE_CASH') &&
                                <div className="dropdown-item c-pointer" onClick={(e) => this.updateStatus(e, 'ONHOLD')}>
                                    <FontAwesomeIcon icon={['far', 'hand-paper']} className="fa-fw" /> Mark as On Hold
                                </div>
                                } */}

                                {(this.props.displayAdvanced && this.state.paymentTransaction.status === 'TIMEDOUT') &&
                                <div className="dropdown-item c-pointer" onClick={() => {$('#receipt-payment-transaction').modal('hide'); $('#complete-payment-transaction').modal('show');}}>
                                    <FontAwesomeIcon icon={['fas', 'check']} className="fa-fw" /> Mark as Completed
                                </div>
                                }

                                {(this.props.displayAdvanced && this.state.paymentTransaction.status === 'TIMEDOUT') &&
                                <div className="dropdown-item c-pointer" onClick={() => {$('#receipt-payment-transaction').modal('hide'); $('#fail-payment-transaction').modal('show');}}>
                                    <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw" /> Mark as Failed
                                </div>
                                }

                                {(this.props.displayAdvanced && this.props.userType === "TYPE_ADMIN") &&
                                    <div className="dropdown-item c-pointer" onClick={() => {$('#receipt-payment-transaction').modal('hide'); $('#save-note').modal('show');}}>
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw" /> Save Note
                                    </div>
                                }

                                <div className="dropdown-item c-pointer" onClick={() => window.print()}>
                                    <FontAwesomeIcon icon={['fas', 'print']} className="fa-fw" /> Print Receipt
                                </div>

                            </div>
                        </div>

                    </div>

                </ReceiptPaymentTransaction>

                {this.props.displayAdvanced &&
                <ReceiptScheduledPayment userType={this.props.userType} displayAdvanced={this.props.displayAdvanced} scheduledPayment={this.state.scheduledPayment} hideFundsDisclaimer={true}>

                    <div className="float-left">
                        <ButtonClose/>
                    </div>

                </ReceiptScheduledPayment>
                }

                <div className="modal fade" id="refund-payment-transaction" tabIndex="-1" role="dialog" aria-labelledby="refund-payment-transaction-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.refundPaymentTransaction}>

                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="refund-payment-transaction-label">
                                        Refund Transaction
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                    </button>
                                </div>

                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        Enter the amount you would like to refund from the transaction total{this.props.userType==='TYPE_ADMIN'?" and the transaction fee total.":'.'}
                                    </p>
                                </div>

                                <div className="modal-body mb-2">

                                    <Alert validationList={this.state.validationList} validationType="danger" customClasses="mb-3" />

                                    {(this.state.paymentTransaction.amount != null && this.state.paymentTransaction.reversedAmount != null) &&
                                    <FieldText id="reversedAmount" label="Refund Amount" type="number" prependIconType="fas" prependIconName="dollar-sign"
                                               fieldColumns="12" labelColumns="12" fieldClass="form-control-sm mb-0"
                                               help={`The maximum refundable amount is $${(this.state.paymentTransaction.amount - this.state.paymentTransaction.reversedAmount).toFixed(2)}.`}
                                               placeholder="0.00" labelClass="col-form-label-sm" step="0.01" parent={this} value={this.state.reversedAmount}/>
                                    }

                                    {(this.state.paymentTransaction.feeAmount != null && this.state.paymentTransaction.reversedFeeAmount != null) && this.props.userType !== 'TYPE_MANAGER' &&
                                    <FieldText id="reversedFeeAmount" label="Transaction Fee Refund Amount" type="number" prependIconType="fas" prependIconName="dollar-sign"
                                               fieldColumns="12" labelColumns="12" fieldClass="form-control-sm mb-0"
                                               help={`The maximum refundable transaction fee amount is $${(this.state.paymentTransaction.feeAmount - this.state.paymentTransaction.reversedFeeAmount).toFixed(2)}.`}
                                               placeholder="0.00" labelClass="col-form-label-sm" step="0.01" parent={this} value={this.state.reversedFeeAmount}/>
                                    }

                                </div>

                                <div className="modal-footer d-block text-center bg-secondary rounded-bottom">

                                    <div className="row">
                                        <div className="col">
                                            <div className="float-left">
                                                <div className="btn btn-outline-primary btn-lg" onClick={() => {$('#refund-payment-transaction').modal('hide'); $('#receipt-payment-transaction').modal('show');}}>
                                                    Back
                                                </div>
                                            </div>
                                            <div className="float-right">
                                                <button type="submit" className="btn btn-primary btn-lg">
                                                    Refund
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="payment-transaction-reversals" tabIndex="-1" role="dialog" aria-labelledby="payment-transaction-reversals-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content shadow">

                            <div className="modal-header bg-dark text-white">
                                <h5 className="modal-title" id="payment-transaction-reversals-label">
                                    Reversal History
                                </h5>
                                <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                    <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                </button>
                            </div>

                            <div className="modal-body bg-secondary">
                                <p className="mb-0">
                                    Select a reversal from the list below to view it's corresponding transaction receipt.
                                </p>
                            </div>

                            <div className="modal-body modal-body-table">

                                <table className="table table-hover mb-0">
                                    <thead>
                                    <tr className="small">
                                        <th>
                                            Date
                                        </th>
                                        <th>
                                            Charges
                                        </th>
                                        <th>
                                            Transaction Fee
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.paymentTransactionReversalList.records.map((data, key) => {
                                        return(
                                            <tr key={key} className="small c-pointer" onClick={() => this.viewPaymentTransaction(data)}>
                                                <td>
                                                    <div className="">
                                                        <Moment format="MMM DD, YYYY">
                                                            { data?.source === "PAD" && data?.transactionType === 'PAYMENT'
                                                            ? parseDateForPad(data.createDate)
                                                            : data.createDate}
                                                        </Moment>
                                                    </div>
                                                </td>
                                                <td>
                                                    {data.amount != null &&
                                                    <div className={data.amount < 0 ? 'text-danger' : ''}>
                                                        <FormattedNumber value={data.amount} style={`currency`} currency={data.currency}/>
                                                    </div>
                                                    }
                                                </td>
                                                <td>
                                                    {data.feeAmount != null &&
                                                    <div className={data.feeAmount < 0 ? 'text-danger' : ''}>
                                                        <FormattedNumber value={data.feeAmount} style={`currency`} currency={data.currency}/>
                                                    </div>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>

                            </div>

                            <div className="modal-footer d-block text-center bg-secondary rounded-bottom">

                                <div className="row">
                                    <div className="col">
                                        <div className="float-left">
                                            <div className="btn btn-outline-primary btn-lg" onClick={() => {$('#payment-transaction-reversals').modal('hide'); $('#receipt-payment-transaction').modal('show');}}>
                                                Back
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

                <div className="modal fade" id="settle-payment-transaction" tabIndex="-1" role="dialog" aria-labelledby="settle-payment-transaction-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <form onSubmit={this.savePaymentTransactionSettlementDate}>

                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="settle-payment-transaction-label">
                                        Mark Transaction as Settled
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                    </button>
                                </div>

                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        To mark this transaction as settled, enter a settlement date below.
                                    </p>
                                </div>

                                <div className="modal-body">

                                    <FieldDate id="settlementDate" label="Settlement Date" placeholder="MM/DD/YYYY" required={true} labelColumns="0" fieldColumns="12" fieldClass="form-control-md" labelClass="col-form-label-md d-none" parent={this} value={this.state.settlementDate} />

                                </div>

                                <div className="modal-footer d-block bg-secondary">
                                    <div className="row">
                                        <div className="col-4">
                                            <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => {$("#settle-payment-transaction").modal("hide"); $("#receipt-payment-transaction").modal("show")}}>
                                                <FormattedMessage id="button.back" />
                                            </button>
                                        </div>
                                        <div className="col-8 text-right">
                                            <button type="submit" className="btn btn-primary btn-lg">
                                                Mark as Settled
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="complete-payment-transaction" tabIndex="-1" role="dialog" aria-labelledby="complete-payment-transaction-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <form onSubmit={(e) => this.savePaymentTransactionStatus(e, 'COMPLETE')}>

                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="complete-payment-transaction-label">
                                        Mark Transaction as Completed
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                    </button>
                                </div>

                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        To mark this transaction as completed, please provide the confirmation ID from the payment processor below.
                                    </p>
                                </div>

                                <div className="modal-body">

                                    <FieldText id="confirmationId" label="Confirmation ID" placeholder="Confirmation ID" required={true} labelColumns="0" fieldColumns="12" fieldClass="form-control-md" labelClass="col-form-label-md d-none" model="paymentTransaction" parent={this} value={this.state.paymentTransaction['confirmationId']} />

                                    <FieldDate id="processedDate" label="Date Processed" placeholder="Date Processed" required={true} labelColumns="0" fieldColumns="12" fieldClass="form-control-md" labelClass="col-form-label-md d-none" model="paymentTransaction" parent={this} value={this.state.paymentTransaction['processedDate']} />

                                </div>

                                <div className="modal-footer d-block bg-secondary">
                                    <div className="row">
                                        <div className="col-4">
                                            <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => {$("#complete-payment-transaction").modal("hide"); $("#receipt-payment-transaction").modal("show")}}>
                                                <FormattedMessage id="button.back" />
                                            </button>
                                        </div>
                                        <div className="col-8 text-right">
                                            <button type="submit" className="btn btn-primary btn-lg">
                                                Mark as Completed
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="fail-payment-transaction" tabIndex="-1" role="dialog" aria-labelledby="fail-payment-transaction-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <form onSubmit={(e) => this.savePaymentTransactionStatus(e, 'FAILED')}>

                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="fail-payment-transaction-label">
                                        Mark Transaction as Failed
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                    </button>
                                </div>

                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        To mark this transaction as failed, please provide the error from the payment processor below.
                                    </p>
                                </div>

                                <div className="modal-body">

                                    <FieldTextarea id="error" label="Error" model="paymentTransaction" rows="8" required={true} labelColumns="0" fieldColumns="12" fieldClass="form-control-sm" labelClass="col-form-label-sm d-none" maxLength="200" parent={this} value={this.state.paymentTransaction.error} />

                                </div>

                                <div className="modal-footer d-block bg-secondary">
                                    <div className="row">
                                        <div className="col-4">
                                            <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => {$("#fail-payment-transaction").modal("hide"); $("#receipt-payment-transaction").modal("show")}}>
                                                <FormattedMessage id="button.back" />
                                            </button>
                                        </div>
                                        <div className="col-8 text-right">
                                            <button type="submit" className="btn btn-primary btn-lg">
                                                Mark as Failed
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>

                <Modal id="push-payment-transaction" theme="primary" iconType="fas" iconName="question-circle" title="Push Transaction"
                       body="Are you sure you want to push this transaction to the integrated system?">
                    <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal" onClick={() => {$("#receipt-payment-transaction").modal("show")}}>
                        <FormattedMessage id="button.back" />
                    </button>
                    <button className="btn btn-primary btn-lg" data-dismiss="modal" onClick={() => this.pushPaymentTransaction(this.state.paymentTransaction.id)}>
                        Push Transaction
                    </button>
                </Modal>

                <Modal id="manually-push-payment-transaction" theme="primary" iconType="fas" iconName="question-circle" title="Mark Transaction as Manually Entered"
                       body="Are you sure you want to mark this transaction's push status as manually entered? Performing this action does not push the transaction itself.">
                    <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal" onClick={() => {$("#receipt-payment-transaction").modal("show")}}>
                        <FormattedMessage id="button.back" />
                    </button>
                    <button className="btn btn-primary btn-lg" data-dismiss="modal" onClick={() => this.manuallyPushPaymentTransaction(this.state.paymentTransaction.id)}>
                        Mark as Manually Entered
                    </button>
                </Modal>

                <Modal id="cancel-payment-transaction" theme="primary" iconType="fas" iconName="question-circle" title="Cancel Transaction"
                       body="Are you sure you want to cancel this transaction?">
                    <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal" onClick={() => {$("#receipt-payment-transaction").modal("show")}}>
                        <FormattedMessage id="button.back" />
                    </button>
                    <button className="btn btn-primary btn-lg" data-dismiss="modal" onClick={() => this.cancelPaymentTransaction(this.state.paymentTransaction.id)}>
                        Cancel Transaction
                    </button>
                </Modal>

                <div className="modal fade" id="save-note" tabIndex="-1" role="dialog" aria-labelledby="save-note-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <form onSubmit={(e) => this.saveNote(e)}>

                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="save-note-label">
                                        Save Note
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                    </button>
                                </div>

                                <div className="modal-body">

                                    <FieldText id="note" label="Note" placeholder="Note" required={true} labelColumns="0" fieldColumns="12" fieldClass="form-control-md" labelClass="col-form-label-md d-none" model="paymentTransaction" parent={this} value={this.state.paymentTransaction['note']} />

                                </div>

                                <div className="modal-footer d-block bg-secondary">
                                    <div className="row">
                                        <div className="col-4">
                                            <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => {$("#save-note").modal("hide"); $("#receipt-payment-transaction").modal("show")}}>
                                                <FormattedMessage id="button.back" />
                                            </button>
                                        </div>
                                        <div className="col-8 text-right">
                                            <button type="submit" className="btn btn-primary btn-lg">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="mark-HWpayment-rejected" tabIndex="-1" role="dialog" aria-labelledby="mark-HWpayment-rejected-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <form onSubmit={(e) => this.updatePaymentPayoutStatus(e, 'REJECTED')}>
                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="mark-HWpayment-rejected-label">
                                        Mark Transaction as rejected by Manager
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                    </button>
                                </div>
                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        Are your sure you want to  mark Transaction as rejected by Manager?
                                    </p>
                                </div>
                                <div className="modal-footer d-block bg-secondary">
                                    <div className="row">
                                        <div className="col-4">
                                            <button type="button" className="btn btn-outline-primary btn-md" onClick={() => {$("#mark-HWpayment-rejected").modal("hide"); $("#receipt-payment-transaction").modal("show")}}>
                                                <FormattedMessage id="button.back" />
                                            </button>
                                        </div>
                                        <div className="col-8 text-right">
                                            <button type="submit" className="btn btn-primary btn-md">
                                                Mark rejected by Manager
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="mark-HWpayment-bycheque" tabIndex="-1" role="dialog" aria-labelledby="mark-HWpayment-bycheque-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <form onSubmit={(e) => this.updatePaymentPayoutStatus(e, 'CHEQUE')}>
                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="mark-HWpayment-bychequelabel">
                                        Mark Transaction as sent by cheque
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                    </button>
                                </div>
                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        Are your sure you want to  mark Transaction as sent by cheque?
                                    </p>
                                </div>
                                <div className="modal-footer d-block bg-secondary">
                                    <div className="row">
                                        <div className="col-4">
                                            <button type="button" className="btn btn-outline-primary" onClick={() => {$("#mark-HWpayment-bycheque").modal("hide"); $("#receipt-payment-transaction").modal("show")}}>
                                                <FormattedMessage id="button.back" />
                                            </button>
                                        </div>
                                        <div className="col-8 text-right">
                                            <button type="submit" className="btn btn-primary">
                                                Mark sent by cheque
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>

            </React.Fragment>

        )
    }
}

Transactions.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Transactions);
